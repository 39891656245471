/* modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(21, 20, 20, 0.541);
  display: flex;
  align-items: center;
}
.overlay img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}

.overlay-arrows_left,
.overlay-arrows_right {
  display: flex;
  position: absolute;
  top: 50%;
  z-index: 999;
  cursor: pointer;
}

.overlay-arrows_left {
  left: 10px;
}

.overlay-arrows_right {
  right: 10px;
}

.overlay-arrows_left svg,
.overlay-arrows_right svg {
  width: 50px;
  height: 50px;
}
